/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


:root {
    --bg-blue-dark: #003b5c;
    --bg-gray: #696969;
    --bg-gray-light: #e5e7eb;
}

.bg-blue-dark {
    background-color: var(--bg-blue-dark);
}

.bg-gray {
    background-color: var(--bg-gray);
}

.bg-gray-light {
    background-color: var(--bg-gray-light);
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-blue-dark {
    background-color: var(--bg-blue-dark);
}

a {
    color: #282828;
}

.font-opensans {
    font-family: 'Open Sans', sans-serif;
}



.shadow-b {
    box-shadow: 0 2px 2px -2px #dadada;
}

.border-bottom-light {
    border-bottom: 1px solid #eaebec;
}

.breadcrumb-navigation {
    font-family: 'Open Sans', sans-serif;
    text-transform: capitalize;
    font-size: 0.7755rem;
}

.headings {
    font-family: 'Roboto Slab', sans-serif;
    text-transform: capitalize;
    /* font-size: 1.5rem;*/
    font-size: 1.2rem;
    font-weight: 700;

}

.w-main {
    min-width: calc(100vw - 180px) !important;
}

.stat-section {
    background-color: #c7ecee;
}

.stat-row {
    display: flex;
    padding: 0.5rem 1rem 0.75rem 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.stat-card {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #e9e9e9;
    width: 19%;
}

.stat-heading {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.03rem;
    line-height: 0;
}

.stat-data {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.03rem;
}

.context-menu {
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.context-menu li {
    border-bottom: 1px solid #fafafa;
}

.context-menu li a {
    padding: 0.4rem;
    color: #282828;
    display: flex;

}

.context-menu li a:hover {
    background-color: #686de0;
    text-decoration: none;
    color: #fff;
}

.font-bold {
    font-weight: 700;
}

.right-menu {
    font-size: 0.9rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.right-menu li:hover {
    background-color: #f8f9fa;
}

.right-menu li a {
    text-decoration: none;
}

/*datatable css*/
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
    font-size: 13px !important;
}

.jqx-widget-header {
    font-weight: 600;
}

.jqx-widget-content {
    font-family: 'Open Sans', sans-serif !important;
}

.jqx-widget-header {
    background-color: #f8f9fa !important;
    border-color: #dee2e6 !important;
}

.jqx-widget-content {
    border-color: #dee2e6 !important;
}

/*.jqx-grid-cell-pinned {
    background-color: unset !important;
}*/

/*datatable css*/

.modal-half-page {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    margin: 0;
    overflow-y: scroll;
    min-width: 50vw !important;
    width: 50vw !important;
    max-width: 50vw !important;
    border-left: 6px solid #cacaca;
}

.modal-half-page .modal-content {
    min-height: 100vh !important;
    height: 100vh !important;
    border-radius: 0 !important;
    font-size: 0.875 !important;
    border: 0 !important;
}

/*sweet alert*/
.swal2-header {
    align-items: start !important;
    font-size: 0.675rem;
    font-weight: 500;
}

.swal2-content {
    text-align: left !important;
}

.swal2-popup {
    width: 50rem !important;
}

.swal2-title {
    width: 100% !important;
}

.swal2-actions {
    justify-content: start !important;
    font-size: 12px;
}

button.swal2-confirm.swal2-styled {
    background: #003b5c !important;
}

button.swal2-cancel.swal2-styled {
    background: transparent;
    color: #7d7676;
    border: 1px solid #ccc;
}

.swal2-close {
    top: 13px !important;
    right: 10px !important;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

/*sweet alert*/